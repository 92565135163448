<template>
  <div>
    <b-card no-body class="cardR d-flex" style="min-height: 60vh">
      <div class="d-flex justify-content-between">
        <h1 class="H1Heading">{{ $t("balance.h11") }}</h1>
        <div class="d-flex mt-1 mt-lg-3">
          <router-link to="/balance" style="text-decoration: none">
            <img src="../assets/img/cardA21.svg" alt="" /><span class="P1Text ms-1 d-none d-sm-inline-block">{{
              $t("SideBar.back2") }}</span></router-link>
        </div>
      </div>
      <span v-if="getErrorPayCreated() == null">
        <div class="d-flex w-100 justify-content-between mt-3 mt-md-5">
          <span class="P1Text">{{ $t("balance.p11") }}</span>
          <span class="P1Text">${{ getUsd() }}</span>
        </div>
        <div class="d-flex w-100 justify-content-between mt-3">
          <span class="P1Text">{{ $t("balance.p13") }}</span>
          <span class="P1Text">{{ getGeneralCode() }}</span>
        </div>
        <div class="d-flex w-100 justify-content-between mt-3">
          <span class="P1Text">{{ $t("balance.p12") }}</span>
          <span class="P1Text">{{ getPaymentsMethod() }}</span>
        </div>
      </span>

      <div class="d-flex w-100 justify-content-center text-center mt-3 h-100 align-contetn-center">
        <span class="P1Text" style="color: red" v-html="getErrorPayCreated()">
        </span>
      </div>

      <a class="mt-auto ms-auto me-auto" :href="PayLink" target="_blank" v-if="getGeneralCode() !== 'CloudPayments'">
        <b-button class="greenbtn">{{ $t("balance.btn2") }}</b-button>
      </a>
      <div class="mt-auto ms-auto me-auto" @click="pay" v-else>
        <b-button class="greenbtn">{{ $t("balance.btn2") }}</b-button>
      </div>
    </b-card>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  metaInfo() {
    return {
      title: this.$i18n.t("meta.confirmPay"),
      titleTemplate: "",
      link: [
        {
          rel: "canonical",
          href: "https://https://lk.easypayments.online/confirmPay",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getPayLink"]),
    PayLink: {
      get() {
        return this.getPayLink;
      },
    },
    getPaymentsMethodLocal() {
      return this.getPaymentsMethod();
    },
  },
  mounted() {
    if (this.getPaymentsMethodLocal == null) {
      this.$router.push("/balance");
    }
  },
  methods: {
    ...mapActions(["fetchСreate"]),
    ...mapGetters([
      "getOrderId",
      "getPaymentsMethod",
      "getGeneralCode",
      "getAmount",
      "getUsd",
      "getErrorPayCreated",
      "getDataCloudPayments",
    ]),
    pay() {
      var receipt = {
        Items: [
          //товарные позиции
          {
            label:
              "Консультационные услуги по регистрации компаний и банковских счетов зарубежом", //наименование товара
            price: this.getDataCloudPayments().rub, //цена
            quantity: 1.0, //количество
            amount: this.getDataCloudPayments().rub, //сумма
            vat: null, //ставка НДС
            method: 0, // тег-1214 признак способа расчета - признак способа расчета
            object: 0, // тег-1212 признак предмета расчета - признак предмета товара, работы, услуги, платежа, выплаты, иного предмета расчета
            measurementUnit: "шт", //единица измерения
          },
        ],
        calculationPlace: "easypayments", //место осуществления расчёта, по умолчанию берется значение из кассы
        email: this.getDataCloudPayments().email, //e-mail покупателя, если нужно отправить письмо с чеком
        isBso: false, //чек является бланком строгой отчётности
        AgentSign: null, //признак агента, тег ОФД 1057
        amounts: {
          electronic: this.getDataCloudPayments().rub, // Сумма оплаты электронными деньгами
          advancePayment: 0.0, // Сумма из предоплаты (зачетом аванса) (2 знака после точки)
          credit: 0.0, // Сумма постоплатой(в кредит) (2 знака после точки)
          provision: 0.0, // Сумма оплаты встречным предоставлением (сертификаты, др. мат.ценности) (2 знака после точки)
        },
      };
      let self = this;
      var widget = new cp.CloudPayments();
      widget.pay(
        "charge", // или 'charge'
        {
          //options
          publicId: this.getDataCloudPayments().publicId, //id из личного кабинета
          description: "Пополнение баланса в Личном Кабинете EasyPayments", //назначение
          amount: this.getDataCloudPayments().rub, //сумма
          currency: "RUB", //валюта
          accountId: this.getDataCloudPayments().accountId, //идентификатор плательщика (необязательно)
          invoiceId: this.getDataCloudPayments().invoiceId, //номер заказа  (необязательно)
          email: this.getDataCloudPayments().email, //email плательщика (необязательно)
          skin: "modern", //дизайн виджета (необязательно)
          autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
          data: {
            //содержимое элемента data
            CloudPayments: {
              CustomerReceipt: receipt, //онлайн-чек
            },
          },
        },
        {
          onSuccess: function (options) {
            // console.log("options", options);
            self.$router.push("/balance");
          },
          onFail: function (reason, options) {
            // alert("Ошибка при пополнении баланса");
            // console.log("reason", reason);
            // console.log("options", options);
            // this.$router.push("/balance");
            // fail
            //действие при неуспешной оплате
          },
          onComplete: function (paymentResult, options) {
            //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
            //например вызов вашей аналитики
            // console.log("paymentResult", paymentResult);
            // console.log("options", options);
            self.$router.push("/balance");
          },
        }
      );
    },
  },
};
</script>
